import React, { useState } from "react";
import styles from "./Login.module.scss";
import { Col, Row } from "../common/Layout";
import TextField from "../common/TextField";
import Button from "../common/Button";

import backgroundLeft from "../../static/images/bcg_left_login.svg";
import NetworkService from "../../NetworkService";
import DownloadAppSection from "../common/DownloadAppSection";
import { Link, useNavigate } from "react-router-dom";

const initialData = {
  email: "",
  password: "",
};

function ForgotPasswordPage() {
  const [formData, setFormData] = useState(initialData);

  const navigate = useNavigate();

  const [error, setError] = useState(false);

  const [successfullySent, setSuccessfullySent] = useState();

  const handleFormDataChange = (propName, value) => {
    setFormData({
      ...formData,
      [propName]: value,
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    loginFunc();
  };

  const loginFunc = (e) => {
    setError(false);
    NetworkService.forgotPassword(formData.email)
      .then((res) => {
        setSuccessfullySent(true);
      })
      .catch((res) => {
        setSuccessfullySent(false);
        setError(true);
      });
  };

  return (
    <Row className={styles.loginPage}>
      <Col style={{ background: `url(${backgroundLeft})` }} xl={6} lg={6} xs={12} className={styles.loginLeft}>
        {!successfullySent ? (
          <div>
            <div className={styles.header}>Forgot password?</div>
            <div className={styles.description}>Enter your email address associated with your account</div>

            <form onSubmit={(e) => formSubmit(e)} className={styles.loginForm}>
              <TextField
                onChange={(val) => handleFormDataChange("email", val)}
                name="email"
                type="email"
                placeHolder="E-mail"
              />
              {error && <div className={styles.wrongCredentials}>Wrong email or password.</div>}
              <Button className={styles.submitButton} variant="green" type="submit">
                Send email
              </Button>
              <Link className={styles.forgotPasswordLink} to="/login">
                Back to login
              </Link>
            </form>
          </div>
        ) : (
          <div className={styles.loginForm}>
            <div className={styles.header}>An email has been sent to you.</div>
            <div className={styles.description}>Please follow instructions provided in email.</div>
            <Button onClick={() => navigate("/login")} className={styles.submitButton} variant="green">
              Back to login
            </Button>
          </div>
        )}
      </Col>

      <DownloadAppSection />
    </Row>
  );
}

export default ForgotPasswordPage;
