import React, { useEffect, useState } from "react";
import ReactModalWrap from "../common/ReactModalWrap";
import TextField from "../common/TextField";
import styles from "./Modal.module.scss";
import Button from "../common/Button";
import NetworkService from "../../NetworkService";
import { downloadFile, toasterFunction } from "../utils";
import { Message } from "rsuite";


const initialData = {
  responsiblePerson: "",
  responsiblePersonPosition: "",
}

function AnnexModal({ isOpen, close }) {
  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false)

  const downloadAnnex = (formData) => {
    setLoading(true)
    NetworkService.getAnnex(formData).then((res) => {
      console.log(res?.data)
      downloadFile(res?.data, "anex")
      setLoading(false)
      close()
    toasterFunction(<Message closable type="success">Annex successfully downloaded</Message>);
    });
  };

  useEffect(() => {
    setFormData(initialData)
  },[isOpen])

  return (
    <ReactModalWrap isOpen={isOpen} close={close}>
      <div className={styles.content}>
        <div className={styles.header}>Download Annex</div>

        <TextField
          onChange={(val) => setFormData({ ...formData, responsiblePerson: val })}
          value={formData?.responsiblePerson}
          labelClassName={styles.label}
          label="Responsible person"
          placeHolder="Enter responsible person name"
        />
        <TextField
          onChange={(val) => setFormData({ ...formData, responsiblePersonPosition: val })}
          value={formData?.responsiblePersonPosition}
          labelClassName={styles.label}
          label="Responsible person position"
          placeHolder="Enter responsible person position"
          type="email"
        />

        <Button
          disabled={!(formData.responsiblePerson && formData.responsiblePersonPosition)}
          onClick={() => downloadAnnex(formData)}
          variant="green"
          className={styles.sendButton}>
          Download Annex
        </Button>
      </div>
    </ReactModalWrap>
  );
}

export default AnnexModal;
