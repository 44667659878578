import React, { useEffect, useState } from "react";
import ReactModalWrap from "../common/ReactModalWrap";
import styles from "./Modal.module.scss";
import Button from "../common/Button";
import { CarClasses } from "../utils";
import Checkbox from "../common/Checkbox";

function AllowedClassesModal({ open, close, initalModalData, onSubmit }) {
  const [selectedClasses, setSelectedClasses] = useState([]);

  useEffect(() => {
    if (initalModalData) {
      const company_user_info = initalModalData?.company_user_info;
      if (company_user_info) {
        const allowedClasses = company_user_info.allowed_classes || "";
        setSelectedClasses(allowedClasses.split(","));
      }
    }
  }, [initalModalData, open]);

  const onChange = (id, val) => {
    if (val) {
      setSelectedClasses([...selectedClasses, id]);
    } else {
      const filtered = selectedClasses.filter((c) => c != id);
      console.log(selectedClasses);
      setSelectedClasses(filtered);
    }
  };

  return (
    <ReactModalWrap isOpen={open} close={close}>
      <div className={styles.content}>
        <div className={styles.header}>Chose allowed classes</div>

        {Object.keys(CarClasses).map((c, i) => (
          <ClassItem
            classData={CarClasses[c]}
            onChange={(id, val) => onChange(id, val)}
            id={c}
            label={CarClasses[c].label}
            selected={selectedClasses.includes(c)}
          />
        ))}

        <Button
          disabled={selectedClasses.length == 0}
          onClick={() => onSubmit({ allowed_classes: selectedClasses.join(",") })}
          className={styles.userSubmit}
          variant="green">
          Save changes
        </Button>
      </div>
    </ReactModalWrap>
  );
}

export default AllowedClassesModal;

const ClassItem = ({ selected, id, onChange, classData }) => {
  return (
    <div onClick={() => onChange(id, !selected)} className={styles.classItem}>
      <div className={styles.cselect}>
        <Checkbox selected={selected} onChange={() => onChange(id, !selected)} />
      </div>
      <div className={styles.clabel}>{classData.label}</div>
      <div className={styles.cdescription}>{classData.description}</div>
    </div>
  );
};
