import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./Components/pages/Login";
import { AppContext, GlobalContextProvider } from "./GlobalContext";
import { useContext, useEffect } from "react";
import Dashboard from "./Components/pages/Dashboard";
import "rsuite/dist/rsuite.min.css";
import NetworkService from "./NetworkService";
import UsersPage from "./Components/pages/UsersPage";
import UserPage from "./Components/pages/UserPage";
import RidesPage from "./Components/pages/RidesPage";
import TransactionsPage from "./Components/pages/TransactionsPage";
import InvoicesPage from "./Components/pages/InvoicesPage";
import ForgotPasswordPage from "./Components/pages/ForgotPasswordPage";
import ResetPasswordPage from "./Components/pages/ResetPasswordPage";

function App() {
  return (
    <GlobalContextProvider>
      <RoutesWrap />
    </GlobalContextProvider>
  );
}

const RoutesWrap = () => {
  const { user: a } = useContext(AppContext);

  const [user, setUser] = a;

  useEffect(() => {
    if (user?.authorisation?.token) {
      NetworkService.jwtToken = user?.authorisation?.token;
    }
  }, [user?.authorisation?.token]);

  return (
    <BrowserRouter>
      <Routes>
        {!user && <Route path="/forgot-password" element={<ForgotPasswordPage />} />}
        {!user && <Route path="/reset-password" element={<ResetPasswordPage />} />}
        {!user && <Route path="/login" element={<Login />} />}
        {user && <Route path="/" element={<Dashboard />} />}
        {user && <Route path="/services" element={<RidesPage />} />}
        {user && <Route path="/users/:userId" element={<UserPage />} />}
        {user && <Route path="/users" element={<UsersPage />} />}
        {user && <Route path="/transactions" element={<TransactionsPage />} />}
        {user && <Route path="/invoices" element={<InvoicesPage />} />}

        <Route path="*" element={user ? <Navigate to="/" /> : <Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
