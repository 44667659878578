import React, { useContext, useEffect, useState } from "react";
import PageWrap from "../common/PageWrap";
import UsersTable from "../tables/UsersTabele";
import { AppContext } from "../../GlobalContext";
import NetworkService from "../../NetworkService";
import styles from "./UsersPage.module.scss";
import { Container, Row } from "../common/Layout";
import Search from "../common/Search";
import Button from "../common/Button";
import UserModal from "../modals/UserModal";
import ConfirmationModal from "../modals/ConfirmationModal";
import { toasterFunction } from "../utils";
import { Message } from "rsuite";
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";

const initialUserModalData = {
  email: "",
  number: "",
  employeeDetails: "",
  limit: "",
};

function UsersPage() {
  const { user: a } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [user, setUser] = a;
  const [data, setData] = useState();

  const [userModal, setUserModal] = useState(null);
  const [deleteUserModalData, setDeleteUserModalData] = useState(null);

  const [pagination, setPagination] = useState({
    size: 15,
    page: 1,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const searchHandler = debounce((value) => {
    searchParams.set("search", value)
    setSearchParams(searchParams.toString())
  }, 1000);

  const getUsers = (pagination, search) => {
    NetworkService.getUsers(pagination, search)
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    if (user && NetworkService.jwtToken) {
      getUsers(pagination, searchParams.get("search"));
    }
  }, [user, pagination, searchParams, NetworkService.jwtToken]);

  const handleDelete = async (deleteUserModalData) => {
    await NetworkService.deleteUser(deleteUserModalData?.id).then(() => {
      setDeleteUserModalData(null);
      toasterFunction(
        <Message closable showIcon type="success">
          User successfully removed.
        </Message>,
        { duration: 5000 }
      );
    });
    getUsers(pagination);
  };

  return (
    <PageWrap>
      <UserModal
        open={!!userModal}
        close={() => setUserModal(null)}
        initalModalData={userModal}
        onSubmit={() => {
          setUserModal(null);
          getUsers(pagination);
        }}
      />
      <ConfirmationModal
        isOpen={!!deleteUserModalData}
        close={() => setDeleteUserModalData(null)}
        handleYes={() => handleDelete(deleteUserModalData)}
      />
      <Container className={styles.ridesPageWrap}>
        <div className={styles.header}>
          Manage your company users:
          <Button
            className={styles.addNewUserButton}
            variant="white"
            onClick={() => setUserModal(initialUserModalData)}>
            Add New User
          </Button>
        </div>
        <Row className={styles.tableWrap}>
          <div className={styles.tableHeader}>List of all Users:</div>
          <Row className={styles.filters}>
            <Search
              onChange={(val) => searchHandler(val)}
              defaultValue={searchParams.get("search")}
              placeHolder="Search by ID or Name..."
            />
          </Row>
        </Row>

        <UsersTable
          pagination={pagination}
          onPaginationChange={(e) => setPagination({ ...pagination, ...e })}
          editUser={(e) => {
            setUserModal(e);
          }}
          deleteUser={(e) => setDeleteUserModalData(e)}
          loading={loading}
          data={data}
        />
      </Container>
    </PageWrap>
  );
}

export default UsersPage;
