import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Breadcrumb } from "rsuite";
import { Container } from "./Layout";
import styles from "./BreadCrumbsWrap.module.scss";

function BreadCrumbsWrap() {
  const [breadCrumbsArray, setBreadCrumbsArray] = useState([]);

  const location = useLocation();

  useEffect(() => {
    const pathNameArray = location.pathname.split("/");
    setBreadCrumbsArray(pathNameArray);
  }, [location]);

  return (
    <Container className={styles.breadCrumbWrap}>
      {location?.pathname !== "/" && (
        <Breadcrumb>
          {breadCrumbsArray.map((item, key) => {
            let labelRender = item;
            if (!item) {
              labelRender = "Home";
            }
            return (
              <Breadcrumb.Item
                style={{ textTransform: "capitalize" }}
                href={`/${item}`}
                active={key == breadCrumbsArray.length - 1}>
                {labelRender}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      )}
    </Container>
  );
}

export default BreadCrumbsWrap;
