import React from "react";
import classnames from "classnames";
import "./Layout.module.scss";

export function Container ({children, className, ...rest}) {
  return (
    <div className={classnames("container2", className)} {...rest}>{children}</div>
  );
}

export function Row ({children, className, ...rest}) {
  return (
    <div className={classnames("row2", className)} {...rest}>{children}</div>
  );
}

export function Col ({
  xs,
  sm,
  md,
  lg,
  xl,
  children,
  className,
  ...rest
}) {
  return (
    <div className={classnames(className, {
      ["col"]: true,
      [`col-${xs}`]: xs > 0,
      [`col-sm-${sm}`]: sm > 0,
      [`col-md-${md}`]: md > 0,
      [`col-lg-${lg}`]: lg > 0,
      [`col-xl-${xl}`]: xl > 0,
    })}
    {...rest}>{children}</div>
  );
}
