import React from "react";
import styles from "./PageWrap.module.scss";
import Header from "../Header";
import BreadCrumbsWrap from "./BreadCrumbsWrap";
import BurgerMenu from "../BurgerMenu";
import Footer from "../Footer";

function PageWrap({ children }) {
  return (
    <div className={styles.backgroundWrap}>
      <Header />
      <div className={styles.pageWrap}>
        <BreadCrumbsWrap />
        {children}
      </div>
      <Footer />
      <BurgerMenu />
    </div>
  );
}

export default PageWrap;
