import React, { useContext, useEffect, useState } from "react";
import PageWrap from "../common/PageWrap";
import { AppContext } from "../../GlobalContext";
import NetworkService from "../../NetworkService";
import styles from "./UsersPage.module.scss";
import { Container, Row } from "../common/Layout";
import Search from "../common/Search";
import TransactionsTable from "../tables/TransactionsTable";
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";

function TransactionsPage() {
  const { user: a } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [user] = a;
  const [data, setData] = useState();

  const [pagination, setPagination] = useState({
    size: 15,
    page: 1,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const searchHandler = debounce((value) => {
    searchParams.set("search", value)
    setSearchParams(searchParams.toString())
  }, 1000);

  useEffect(() => {
    if (user && NetworkService.jwtToken) {
      setLoading(true)
      NetworkService.getTransactions(pagination, searchParams.get("search"))
        .then((res) => {
          setData(res);
          setLoading(false);
        })
        .catch((e) => setLoading(false));
    }
  }, [user, searchParams, NetworkService.jwtToken, pagination]);

  return (
    <PageWrap>
      <Container className={styles.ridesPageWrap}>
        <div className={styles.header}>Review company Transactions:</div>
        <Row className={styles.tableWrap}>
          <div className={styles.tableHeader}>List of Transactions:</div>
          <Row className={styles.filters}>
          <Search
              onChange={(val) => searchHandler(val)}
              defaultValue={searchParams.get("search")}
              placeHolder="Search by ID or Name..."
            />
          </Row>
        </Row>

        <TransactionsTable
          onPaginationChange={(e) => setPagination({ ...pagination, ...e })}
          loading={loading}
          pagination={pagination}
          data={data}
        />
      </Container>
    </PageWrap>
  );
}

export default TransactionsPage;
