import React, { useState, useContext, useEffect } from "react";
import NetworkService from "./NetworkService";
import { refetchCompanyData } from "./Components/utils";

export const AppContext = React.createContext({});

const userFromLS = JSON.parse(localStorage.getItem("user"));

export function GlobalContextProvider({ children }) {
  const [user, setUser] = useState(userFromLS);
  const [openBurger, setOpenBurger] = useState(false);
  const [companyData, setCompanyData] = useState(null);

  const updateUser = (value) => {
    setUser(value);
    localStorage.setItem("user", JSON.stringify(value));
  };

  useEffect(() => {
    if (user) {
      refetchCompanyData(setCompanyData);
    }
  }, [user]);

  return (
    <AppContext.Provider
      value={{
        user: [user, updateUser],
        openBurger: [openBurger, setOpenBurger],
        companyData: [companyData, setCompanyData],
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useGlobalContext() {
  return useContext(AppContext);
}
