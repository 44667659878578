import React, { useEffect, useState } from "react";
import ReactModalWrap from "../common/ReactModalWrap";
import TextField from "../common/TextField";
import styles from "./Modal.module.scss";
import Button from "../common/Button";
import NetworkService from "../../NetworkService";
import { downloadFile, toasterFunction } from "../utils";
import { Message } from "rsuite";

const initialData = {
  responsiblePerson: "",
  responsiblePersonEmail: "",
};

function PersonalDataAgreementModal({ isOpen, close }) {
  const [formData, setFormData] = useState(initialData);

  const [loading, setLoading] = useState(false)

  const downloadAnnex = (formData) => {
    setLoading(true)
    NetworkService.getPersonalDataAgreement(formData).then((res) => {
      console.log(res?.data);
      downloadFile(res?.data, "personal-data-agreement");
      setLoading(false)
      close();
      toasterFunction(
        <Message closable type="success">
          Data agreement successfully downloaded
        </Message>
      );
    });
  };

  useEffect(() => {
    setFormData(initialData);
  }, [isOpen]);

  return (
    <ReactModalWrap isOpen={isOpen} close={close}>
      <div className={styles.content}>
        <div className={styles.header}>Download Personal Data Agreement</div>

        <TextField
          onChange={(val) => setFormData({ ...formData, responsiblePerson: val })}
          value={formData?.responsiblePerson}
          labelClassName={styles.label}
          label="Responsible person"
          placeHolder="Enter responsible person name"
        />
        <TextField
          onChange={(val) => setFormData({ ...formData, responsiblePersonEmail: val })}
          value={formData?.responsiblePersonEmail}
          labelClassName={styles.label}
          label="Responsible person email"
          placeHolder="Enter responsible person email"
          type="email"
        />

        <Button
          disabled={!(formData.responsiblePerson && formData.responsiblePersonEmail) || loading}
          onClick={() => downloadAnnex(formData)}
          variant="green"
          className={styles.sendButton}>
          Download Personal Data Agreement
        </Button>
      </div>
    </ReactModalWrap>
  );
}

export default PersonalDataAgreementModal;
