import React, { useEffect, useState } from "react";
import ReactModalWrap from "../common/ReactModalWrap";
import styles from "./Modal.module.scss";
import TextField from "../common/TextField";
import Button from "../common/Button";

function CreditValueEditorModal({
  open,
  close,
  initialLimitValue = null,
  onSubmit,
  header,
  subHeader,
  btnLabel,
  minValue = 0
}) {
  const [value, setValue] = useState(initialLimitValue);

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (initialLimitValue != null) {
      setValue(Number.parseFloat(value || 0));
    }
  }, [initialLimitValue]);

  const onChange = (val) => {
    const parsedVal = Number.parseInt(val)
    if(val =="" || isNaN(parsedVal)) {
      setValue(0);
      return
    }
    setValue(parsedVal)
  };

  return (
    <ReactModalWrap isOpen={open} close={close}>
      <div className={styles.content}>
        <div className={styles.header}>{header}</div>
        <div className={styles.numberHandler}>
          <Button
            disabled={value <= minValue}
            variant="white"
            className={styles.buttonMinus}
            onClick={() => {
              if (value - 500 < minValue) {
                onChange(minValue);
              } else {
                onChange(value - 500.0);
              }
            }}
          >
            -
          </Button>

          <TextField
            label={subHeader}
            labelClassName={styles.labelClassName}
            className={styles.limitTextField}
            value={value}
            onChange={(val) => {
              onChange(val);
            }}
          />
          <Button
            variant="white"
            className={styles.buttonPlus}
            onClick={() => {
              onChange(value + 500);
            }}
          >
            +
          </Button>
        </div>

        <Button
          onClick={ async () => {
            setLoading(true);
            await onSubmit(value);
            setLoading(false)
          }}
          disabled={value < minValue || loading}
          className={styles.setNewLimit}
          variant="green"
        >
          {btnLabel}
        </Button>
      </div>
    </ReactModalWrap>
  );
}

export default CreditValueEditorModal;
