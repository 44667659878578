import React, { useContext, useEffect, useState } from "react";
import styles from "./Dashboard.module.scss";
import { Col, Container, Row } from "../common/Layout";
import MainCard from "../common/MainCard";
import Cards from "../Cards";
import { AppContext } from "../../GlobalContext";
import ModalsSection from "../ModalsSection";
import PageWrap from "../common/PageWrap";
import RidesTable from "../tables/RidesTable";
import Button from "../common/Button";
import NetworkService from "../../NetworkService";
import moment from "moment";
import CreditValueEditorModal from "../modals/CreditValueEditorModal";
import { useNavigate } from "react-router-dom";
import { refetchCompanyData, toasterFunction } from "../utils";
import { Message } from "rsuite";

function Dashboard() {
  const { user: a, companyData: b } = useContext(AppContext);
  const [user] = a;
  const [companyData, setCompanyData] = b;
  const [openCreditEditModal, setOpenCreditEditModal] = useState(null);

  const [loading, setLoading] = useState(true);

  const [todaysRides, setTodaysRides] = useState([]);

  const navigate = useNavigate();

  const addCredit = () => {
    setOpenCreditEditModal({ modalType: "add-credit" });
  };

  const editLimit = () => {
    setOpenCreditEditModal({
      modalType: "edit-limit",
    });
  };

  useEffect(() => {
    if (user && NetworkService.jwtToken) {
      NetworkService.getRides(
        moment().startOf("day").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      )
        .then((res) => {
          setTodaysRides(res);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [user, NetworkService.jwtToken]);

  const changeCompanyLimit = async (credit) => {
    console.log(credit);
    await NetworkService.editCompanyCreditLowLimit(credit)
    setOpenCreditEditModal(false);
    toasterFunction(<Message closable type="success">Company limit successfully changed.</Message>);
    refetchCompanyData(setCompanyData);
  };

  return (
    <PageWrap>
      {openCreditEditModal && (
        <CreditValueEditorModal
          header="Change company credit low limit"
          btnLabel="Set new limit"
          subHeader="Limit in RSD"
          onSubmit={(credit) => changeCompanyLimit(credit)}
          open={!!openCreditEditModal}
          initialLimitValue={companyData?.company?.credit_low_limit}
          close={() => setOpenCreditEditModal(false)}
        />
      )}
      <Container className={styles.dashboard}>
        <div className={styles.greetingsContainer}>
          <div className={styles.header}>
            Hey {user?.user?.name},check your account:
          </div>

          <div className={styles.description}>
            Welcome,{companyData?.company?.name}
            <br />
            Here, in one place, you can find information about the status and consumption for your company
          </div>
        </div>
        <Row className={styles.allCards}>
          <MainCard onAddCreditClick={() => addCredit()} />
          <Col
            className={styles.cardsWrap}
            xl={8}
            lg={6}
            md={6}
            xs={12}
            sm={12}
          >
            <Cards
              header={"Current balance:"}
              value={
                Number.parseFloat(companyData?.company?.credit || 0)?.toFixed(
                  2
                ) + " RSD"
              }
            />
            <Cards
              onClick={() => editLimit()}
              header={"Company credit low limit:"}
              value={
                Number.parseFloat(
                  companyData?.company?.credit_low_limit || 0
                )?.toFixed(2) + " RSD"
              }
              button={
                <Button
                  className={styles.editLimitButton}
                  variant="white"
                  onClick={() => setOpenCreditEditModal(true)}
                >
                  Edit
                </Button>
              }
            />
            <Cards
              header={"Today rides:"}
              value={companyData?.ridesToday || 0}
            />
            <Cards
              header={"Today money spent: "}
              value={
                Number.parseFloat(companyData?.expenseToday || 0)?.toFixed(2) +
                " RSD"
              }
            />
          </Col>
        </Row>
        <ModalsSection />
        <div className={styles.tableHeaderWrap}>
          <div className={styles.tableHeader}>Todays last services:</div>
          <Button
            onClick={() => {
              navigate("/services");
            }}
            className={styles.showAllButton}
            variant="white"
          >
            View All
          </Button>
        </div>
        <RidesTable
          hidePagination={true}
          loading={loading}
          data={todaysRides}
          onPaginationChange={() => { }}
        />
      </Container>
    </PageWrap>
  );
}

export default Dashboard;
