import classNames from "classnames";
import React, { useEffect } from "react";
import { Pagination, Table } from "rsuite";
import styles from "./TableStyles.module.scss";

const pageSizeOptions = [10, 15, 20, 30, 50, 100, 200, 500, 1000];
const layout = ["total", "-", "limit", "pager"];
export default function TableWrapper({
  data,
  rowClassName,
  loading,
  totalElements,
  columns,
  onChange,
  // commonColumnProps,
  // staticContext,
  hidePagination,
  activePage,
  pageSize,
  renderEmpty,
  children,
  ...rest
}) {
  const total = totalElements || data.length;

  return (
    <div className={styles.container}>
      <Table
        wordWrap
        renderEmpty={renderEmpty}
        autoHeight
        rowClassName={(rowData) => {
          if (!rowClassName) {
            return styles.row;
          }
          if (typeof rowClassName === "string") {
            return classNames(styles.row, rowClassName);
          }
          return classNames(styles.row, rowClassName(rowData));
        }}
        className={styles.table}
        shouldUpdateScroll={false}
        loading={loading}
        data={data || []}
        headerHeight={60}
        rowHeight={60}
        {...rest}>
        {columns.map((element) => {
          return (
            <Table.Column fullText width={110} key={element.header} resizable {...(element || {}).options}>
              <Table.HeaderCell className={styles.header}>{element.header}</Table.HeaderCell>
              <Table.Cell className={classNames(styles.cell, element.cellClassName)} dataKey={element.dataKey}>
                {element.render}
              </Table.Cell>
            </Table.Column>
          );
        })}
      </Table>
      {!hidePagination && (
        <Pagination
          className={styles.paginationWrap}
          first
          last
          prev
          next
          activePage={activePage}
          onChangePage={(page) => {
            if (onChange) {
              onChange({
                page: page,
              });
            }
          }}
          size="md"
          layout={layout}
          total={total}
          maxButtons={5}
          ellipsis
          limitOptions={pageSizeOptions}
          limit={pageSize}
          onChangeLimit={(size) => {
            if (onChange) {
              onChange({
                size,
              });
            }
          }}
        />
      )}
    </div>
  );
}
