import React, { useEffect, useState } from "react";
import ReactModalWrap from "../common/ReactModalWrap";
import TextField from "../common/TextField";
import { DatePicker, DateRangePicker, Message } from "rsuite";
import styles from "./Modal.module.scss";
import Button from "../common/Button";
import NetworkService from "../../NetworkService";
import { toasterFunction } from "../utils";

const initialData = {
  responsiblePerson: "",
  responsiblePersonEmail: "",
  responsiblePersonPosition: "",
};

function ContractModal({ isOpen, close }) {
  const [formData, setFormData] = useState(initialData);
  const [disableButton, setDisableButton] = useState(false)

  const downloadContract = (formData) => {
    setDisableButton(true)
    NetworkService.getContract(formData).then((res) => {
      toasterFunction(<Message closable type="success">Contract successfully sent to your email.</Message>);
      close();
      setDisableButton(false)
    });
  };

  useEffect(() => {
    setFormData(initialData);
  }, [isOpen]);

  return (
    <ReactModalWrap isOpen={isOpen} close={close}>
      <div className={styles.content}>
        <div className={styles.header}>Download contract</div>

        <TextField
          onChange={(val) => setFormData({ ...formData, responsiblePerson: val })}
          value={formData?.responsiblePerson}
          labelClassName={styles.label}
          label="Responsible person"
          placeHolder="Enter responsible person name"
        />
        <TextField
          onChange={(val) => setFormData({ ...formData, responsiblePersonEmail: val })}
          value={formData?.responsiblePersonEmail}
          labelClassName={styles.label}
          label="Responsible person email"
          placeHolder="Enter responsible person email"
          type="email"
        />
        <TextField
          onChange={(val) => setFormData({ ...formData, responsiblePersonPosition: val })}
          value={formData?.responsiblePersonPosition}
          labelClassName={styles.label}
          label="Responsible person position"
          placeHolder="Enter responsible person position"
        />

        <Button
          disabled={
            !(formData.responsiblePerson && formData.responsiblePersonEmail && formData.responsiblePersonPosition) || disableButton
          }
          onClick={() => downloadContract(formData)}
          variant="green"
          className={styles.sendButton}>
          Send Contract
        </Button>
      </div>
    </ReactModalWrap>
  );
}

export default ContractModal;
