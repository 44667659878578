import React from "react";
import classnames from "classnames";
import styles from "./TextField2.module.scss";

export default function TextField({ label, type = "text", invalid = false, className, onChange, ...rest }) {
  let onInputChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };
  let Element = type === "textarea" ? "textarea" : "input";
  let ret = (
    <Element
      type={type}
      className={classnames(styles.textField, !!label ? className : "", {
        [styles.invalid]: invalid,
      })}
      onChange={onInputChange}
      {...rest}
    />
  );
  if (label) {
    ret = (
      <div className={classnames(styles.inputWrapper, className)}>
        <label className={styles.label}>{label}</label>
        {ret}
      </div>
    );
  }
  return ret;
}
