import React from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";

const Button = ({ style, className, children, forwardRef, type, variant = "primary", ...rest }) => {
  let aaa = classNames(styles.button, className, {
    [styles.variantGreen]: variant === "green",
    [styles.variantBlack]: variant === "black",
    [styles.variantWhite]: variant === "white",
  });
  if (type === "submit") {
    let value = typeof children === "string" ? children : null;
    return <input type="submit" value={value} className={aaa} {...rest} />;
  }
  return (
    <button aria-pressed style={style} ref={forwardRef} className={aaa} {...rest}>
      {children}
    </button>
  );
};

export default React.forwardRef((props, ref) => <Button {...props} forwardRef={ref} />);
