import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import TableWrapper from "../common/TableWrapper";
import Button from "../common/Button";
import styles from "./TableStyles.module.scss";

const columns = [
  {
    header: "#",
    render: (e) => <b>{e.id}</b>,
    options: {
      width: 250,
    },
  },
  {
    header: "User",
    render: (e) => (
      <>
        <Link className={styles.link} to={`/users/${e.user_id}`}>
          {e?.user?.firstName} {e?.user?.lastName}
        </Link>
      </>
    ),
    options: {
      width: 250,
    },
  },
  {
    header: "Amount",
    render: (e) => <>{e.value} RSD</>,
    options: {
      width: 150,
    },
  },

  {
    header: "Created at",
    render: (e) => <>{moment(e.created_at).format("DD/MM/YYYY   HH:mm")}</>,
    options: {
      width: 250,
    },
  },
];

function TransactionsTable({
  data,
  loading,
  onPaginationChange,
  pagination,
  hidePagination,
}) {

  console.log(data)


  const activePage = data?.current_page;
  const totalElements = data?.total;
  const pageSize = pagination?.size || data?.per_page;
  return (
    <TableWrapper
      activePage={activePage}
      pageSize={pageSize}
      totalElements={totalElements}
      onChange={onPaginationChange}
      pagination={pagination}
      hidePagination={hidePagination}
      loading={loading}
      columns={columns}
      data={data?.data || []}
    />
  );
}

export default TransactionsTable;
