import React, { useEffect, useState } from "react";
import PageWrap from "../common/PageWrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Container, Row } from "../common/Layout";
import Avatar from "../common/Avatar";
import styles from "./UserPage.module.scss";
import Button from "../common/Button";
import { SmallCards } from "../Cards";
import RidesTable from "../tables/RidesTable";
import DatePickerWrap from "../DatePickerWrap";
import Search from "../common/Search";
import NetworkService from "../../NetworkService";
import moment from "moment";
import UserModal from "../modals/UserModal";
import { Classes, getUserById, toasterFunction } from "../utils";
import ConfirmationModal from "../modals/ConfirmationModal";
import { Message, Toggle } from "rsuite";
import { debounce } from "lodash";
import InvoicesModal from "../modals/InvoicesModal";
import AllowedClassesModal from "../modals/AllowedClasses";
import AllowedTimeTables from "../modals/AllowedTimetables";
import CreditValueEditorModal from "../modals/CreditValueEditorModal";

function UserPage({}) {
  const params = useParams();
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    size: 15,
    page: 1,
  });

  const [userData, setUserData] = useState(null);
  const [userDrives, setUserDrives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userModal, setUserModal] = useState(null);
  const [userLimitModal, setUserLimitModal] = useState(null);
  const [allowedClassesModal, setAllowedClassesModal] = useState(null);
  const [allowedTimeTables, setAllowedTimetables] = useState(null);
  const [deleteUserModalData, setDeleteUserModalData] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const [openInvoicesModal, setOpenInvoicesModal] = useState(false);

  const searchHandler = debounce((value) => {
    searchParams.set("search", value);
    setSearchParams(searchParams.toString());
  }, 1000);

  useEffect(() => {
    let from = searchParams.get("from");
    let to = searchParams.get("to");

    if (from && to) {
      from = moment.unix(from / 1000).format("YYYY-MM-DD");
      to = moment.unix(to / 1000).format("YYYY-MM-DD");
    } else {
      from = moment().startOf("month").format("YYYY-MM-DD");
      to = moment().format("YYYY-MM-DD");
    }

    if (NetworkService.jwtToken) {
      setLoading(true);
      NetworkService.getRidesForUser(params.userId, from, to, pagination, searchParams.get("search")).then((res) => {
        setUserDrives(res);
        setLoading(false);
      });
    }
  }, [searchParams, pagination, NetworkService.jwtToken]);

  useEffect(() => {
    if (NetworkService.jwtToken) {
      getUserById(params?.userId, setUserData);
    }
  }, [NetworkService.jwtToken]);

  const handleDelete = async (deleteUserModalData) => {
    await NetworkService.deleteUser(deleteUserModalData?.id).then(() => {
      setDeleteUserModalData(null);
      navigate("/users");
      toasterFunction(
        <Message closable showIcon type="success">
          User successfully removed.
        </Message>,
        { duration: 5000 }
      );
    });
  };

  const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

  if (!userData) {
    return null;
  }

  const allowedTimeSlots = JSON.parse(userData?.company_user_info?.allowed_time_slots || []);
  console.log(allowedTimeSlots);

  const updateUserData = (data) => {
    NetworkService.editUser({
      ...data,
      id: userData.id,
    })
      .then(() => {
        getUserById(params?.userId, setUserData);
        setAllowedTimetables(null);
        setAllowedClassesModal(null);
        setUserModal(null);
        setUserLimitModal(null);
        toasterFunction(
          <Message closable type="success">
            User successfully edited.
          </Message>
        );
      })
      .catch((e) => {
        console.log(e);
        toasterFunction(
          <Message closable type="error">
            An error has ocurred
          </Message>
        );
      });
  };

  return (
    <PageWrap>
      {!!userLimitModal && (
        <CreditValueEditorModal
          header="Change user spending limit"
          btnLabel="Set new spending limit"
          subHeader="Limit in RSD"
          onSubmit={(limit) => {
            updateUserData({ limit: limit });
          }}
          open={!!userLimitModal}
          initialLimitValue={userData?.company_user_info?.spending_credit_limit || 0}
          close={() => setUserLimitModal(null)}
        />
      )}
      <InvoicesModal close={() => setOpenInvoicesModal(false)} isOpen={openInvoicesModal} userId={params.userId} />
      {!!allowedTimeTables && (
        <AllowedTimeTables
          open={!!allowedTimeTables}
          close={() => setAllowedTimetables(null)}
          initalModalData={allowedTimeTables}
          onSubmit={updateUserData}
        />
      )}
      {!!allowedClassesModal && (
        <AllowedClassesModal
          open={!!allowedClassesModal}
          close={() => setAllowedClassesModal(null)}
          initalModalData={allowedClassesModal}
          onSubmit={updateUserData}
        />
      )}
      {!!userModal && (
        <UserModal
          open={!!userModal}
          close={() => setUserModal(null)}
          initalModalData={userModal}
          onSubmit={() => {
            getUserById(params?.userId, setUserData);
            setUserModal(null);
          }}
        />
      )}
      <ConfirmationModal
        isOpen={!!deleteUserModalData}
        close={() => setDeleteUserModalData(null)}
        handleYes={() => handleDelete(deleteUserModalData)}
      />

      <Container>
        <Row className={styles.topRow}>
          <Avatar user={userData}/>
          <div className={styles.name}>{userData?.firstName + " " + userData?.lastName}</div>
          <Button onClick={() => setUserModal(userData)} variant="white" className={styles.actionButton}>
            Edit
          </Button>
          <Button
            onClick={() => {
              setDeleteUserModalData(userData);
            }}
            variant="white"
            className={styles.actionButton}>
            Delete
          </Button>
        </Row>

        <Row>
          <SmallCards header="Email" value={userData?.email || "//"}></SmallCards>
          <SmallCards header="Phone Number" value={userData?.phone || 0}></SmallCards>
          <SmallCards
            header="Employee Details"
            value={userData?.company_user_info?.employee_details || "//"}></SmallCards>
        </Row>

        <div className={styles.subHeader} style={{ marginTop: 50, marginBottom: 20 }}>
          Set user restriction
        </div>

        <Row>
          <SmallCards
            editButtonFunction={() => {
              setUserLimitModal(userData);
            }}
            header="Spending Limit"
            value={userData?.company_user_info?.spending_credit_limit || "//"}></SmallCards>
          <SmallCards
            editButtonFunction={() => {
              setAllowedClassesModal(userData);
            }}
            header="Which class can use?"
            customRender={
              <>
                {userData?.company_user_info?.allowed_classes
                  ?.split(",")
                  ?.map((cl) => Classes[cl])
                  .join(",")}
              </>
            }
          />
          <SmallCards
            value={userData?.company_user_info?.can_priority ? "Yes" : "No"}
            customRender={
              <Toggle
                onChange={(val) => {
                  updateUserData({ id: params?.userId, priority: val ? 1 : 0 });
                }}
                defaultChecked={userData?.company_user_info?.can_priority}
              />
            }
            header="Can use Priority options?"
          />

          <SmallCards
            customRender={
              <Toggle
                onChange={(val) => {
                  updateUserData({ id: params?.userId, tips: val ? 1 : 0 });
                }}
                defaultChecked={userData?.company_user_info?.can_tip || 0}
              />
            }
            value={userData?.company_user_info?.can_tip ? "Yes" : "No"}
            header="Can use tips?"
          />
        </Row>

        <div className={styles.subHeader} style={{ marginTop: 50, marginBottom: 20 }}>
          Allowed use time per days:
        </div>

        <Row>
          {days.map((day) => (
            <SmallCards
              editButtonFunction={() => {
                setAllowedTimetables({ ...userData, day: day });
              }}
              header={day.toUpperCase()}
              value={allowedTimeSlots[day]?.map((d) => d?.join("-"))?.join(", ") || "00:00-23:59"}
            />
          ))}
        </Row>

        <Row className={styles.tableWrap}>
          <div className={styles.tableHeader}>User services:</div>
          <Row className={styles.filters}>
            <Search
              defaultValue={searchParams.get("search")}
              onChange={(val) => searchHandler(val)}
              placeHolder="Search by ID or Address..."
            />
            <DatePickerWrap />

            <Button onClick={() => setOpenInvoicesModal(true)} className={styles.downloadAllButton} variant="green">
              Download all invoices
            </Button>
          </Row>
        </Row>
        <RidesTable
          onPaginationChange={(e) => setPagination({ ...pagination, ...e })}
          pagination={pagination}
          data={userDrives}
          loading={loading}
        />
      </Container>
    </PageWrap>
  );
}

export default UserPage;
