import React, { useContext, useState } from "react";
import styles from "./ModalsSection.module.scss";
import { Row } from "./common/Layout";
import Button from "./common/Button";
import downloadIcon from "../static/images/download-icon.svg";
import MonthlyReportModal from "./modals/MonthlyReportModal";
import ContractModal from "./modals/ContractModal";
import AnnexModal from "./modals/AnexModal";
import IdentificationForm from "./modals/IdentificationForm";
import PersonalDataAgreementModal from "./modals/PersonalDataAgreementModal";
import { AppContext } from "../GlobalContext";

function ModalsSection() {
  const [monthlyReportModal, setMonthlyReportModal] = useState(false);
  const [yourContract, setYourContract] = useState(false);
  const [yourAnnex, setYourAnnex] = useState(false);
  const [identificationFrom, setIdentificationFrom] = useState(false);
  const [personalDataForm, setPersonalDataForm] = useState(false);

  const { companyData: a } = useContext(AppContext);

  const [companyData] = a

  console.log(companyData)

  return (
    <>
      <MonthlyReportModal isOpen={monthlyReportModal} close={() => setMonthlyReportModal(false)} />
      <ContractModal isOpen={yourContract} close={() => setYourContract(false)} />
      <AnnexModal isOpen={yourAnnex} close={() => setYourAnnex(false)} />
      <IdentificationForm isOpen={identificationFrom} close={() => setIdentificationFrom(false)} />
      <PersonalDataAgreementModal isOpen={personalDataForm} close={() => setPersonalDataForm(false)} />
      

      <div className={styles.modalSection}>
        <Row className={styles.header}>Download: </Row>
        <Row className={styles.modalSectionButtonsWrap}>
          <Button className={styles.button} variant="white" onClick={() => setMonthlyReportModal(true)}>
            <img src={downloadIcon} alt="download icon"/> Monthly report
          </Button>
          <Button className={styles.button} variant="white" onClick={() => setYourContract(true)}>
            <img src={downloadIcon} alt="download icon"/> Your contract
          </Button>
          {!companyData?.is_new  && <Button className={styles.button} variant="white" onClick={() => setYourAnnex(true)}>
            <img src={downloadIcon} alt="download icon"/> Your annex
          </Button>}
          <Button className={styles.button} variant="white" onClick={() => setIdentificationFrom(true)}>
            <img src={downloadIcon} alt="download icon"/> Identification form
          </Button>
          <Button className={styles.button} variant="white" onClick={() => setPersonalDataForm(true)}>
            <img src={downloadIcon} alt="download icon"/> Personal Data Agreement
          </Button>
        </Row>
      </div>
    </>
  );
}

export default ModalsSection;
