import React, { useContext, useEffect, useState } from "react";
import styles from "./RidesPage.module.scss";
import PageWrap from "../common/PageWrap";
import { Container, Row } from "../common/Layout";
import DatePickerWrap from "../DatePickerWrap";
import Search from "../common/Search";
import { AppContext } from "../../GlobalContext";
import NetworkService from "../../NetworkService";
import moment from "moment";
import RidesTable from "../tables/RidesTable";
import { useSearchParams } from "react-router-dom";
import { Message } from "rsuite";
import { toasterFunction } from "../utils";
import { debounce } from "lodash";
import Button from "../common/Button";
import InvoicesModal from "../modals/InvoicesModal";

function RidesPage() {
  const { user: a } = useContext(AppContext);
  let [searchParams, setSearchParams] = useSearchParams();

  const [pagination, setPagination] = useState({
    size: 15,
    page: 1,
  });

  const [loading, setLoading] = useState(true);
  const [user, setUser] = a;
  const [data, setData] = useState();
  const [openInvoicesModal, setOpenInvoicesModal] = useState(false);

  const searchHandler = debounce((value) => {
    searchParams.set("search", value);
    setSearchParams(searchParams.toString());
  }, 1000);

  useEffect(() => {
    setLoading(true);
    let from = searchParams.get("from");
    let to = searchParams.get("to");

    if (from && to) {
      from = moment.unix(from / 1000).format("YYYY-MM-DD");
      to = moment.unix(to / 1000).format("YYYY-MM-DD");
    } else {
      from = moment().startOf("month").format("YYYY-MM-DD");
      to = moment().format("YYYY-MM-DD");
    }

    if (user && NetworkService.jwtToken) {
      NetworkService.getRides(from, to, pagination, searchParams.get("search"))
        .then((res) => {
          setData(res);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          toasterFunction(
            <Message closable showIcon type="error">
              Error while fetching users, please try again
            </Message>,
            { duration: 5000 }
          );
        });
    }
  }, [user, searchParams, pagination, NetworkService.jwtToken]);

  return (
    <PageWrap>
      <InvoicesModal close={() => setOpenInvoicesModal(false)} isOpen={openInvoicesModal} />
      <Container className={styles.ridesPageWrap}>
        <div className={styles.header}>Review your company Services:</div>
        <Row className={styles.tableWrap}>
          <div className={styles.tableHeader}>List of all Services:</div>
          <Row className={styles.filters}>
            <Button onClick={() => setOpenInvoicesModal(true)} className={styles.downloadAllButton} variant="green">
              Download all invoices
            </Button>
            <DatePickerWrap />
            <Search
              onChange={(val) => searchHandler(val)}
              defaultValue={searchParams.get("search")}
              placeHolder="Search by ID or Address..."
            />
          </Row>
        </Row>

        <RidesTable
          onPaginationChange={(e) => setPagination({ ...pagination, ...e })}
          pagination={pagination}
          loading={loading}
          data={data}
        />
      </Container>
    </PageWrap>
  );
}

export default RidesPage;
