import React, { useContext, useRef, useState } from "react";
import styles from "./BurgerMenu.module.scss";
import { AppContext } from "../GlobalContext";
import classNames from "classnames";
import { Link } from "react-router-dom";

import home from "../static/burger/bxs_home.svg";
import invoices from "../static/burger/iconamoon_invoice-fill.svg";
import services from "../static/burger/mdi_car.svg";
import users from "../static/burger/mdi_users.svg";
import transactions from "../static/burger/mingcute_transfer-3-fill.svg";
import useOnClickOutside from "./hooks/useOnClickOutside";

function BurgerMenu() {
  const { openBurger: a } = useContext(AppContext);
  const [openBurger, setOpenBurger] = a;

  const burgerRef = useRef();

  useOnClickOutside(burgerRef, () => setOpenBurger(false), "burger-button-id");

  return (
    <div
      ref={burgerRef}
      className={classNames(styles.navbar, {
        [styles.menuActive]: openBurger,
      })}>
      <div className={styles.itemsWrap}>
        <Link onClick={() => setOpenBurger(false)} to="/" className={styles.menuItem}>
          <img alt="menu icon" src={home} />
          Home
        </Link>
        <Link onClick={() => setOpenBurger(false)} to="/services" className={styles.menuItem}>
          <img alt="menu icon" src={services} />
          Services
        </Link>
        <Link onClick={() => setOpenBurger(false)} to="/users" className={styles.menuItem}>
          <img alt="menu icon" src={users} />
          Users
        </Link>
        <Link onClick={() => setOpenBurger(false)} to="/transactions" className={styles.menuItem}>
          <img alt="menu icon" src={transactions} />
          Transactions
        </Link>
        {/* <Link onClick={() => setOpenBurger(false)} to="/finances" className={styles.menuItem}>
          <img alt="menu icon" src={finances} />
          Finances
        </Link> */}
        <Link onClick={() => setOpenBurger(false)} to="/invoices" className={styles.menuItem}>
          <img alt="menu icon" src={invoices} />
          Invoices
        </Link>
      </div>
    </div>
  );
}

export default BurgerMenu;
