import React from "react";
import styles from "./Avatar.module.scss";

function Avatar({ user }) {
  console.log(user);

  const userInitials = user?.name?.split(" ");

  let initials = {}

  if(userInitials) {
    initials.firstName = userInitials[0]
    initials.lastName = userInitials[1]
  } else {
    initials.firstName = "N"
    initials.lastName = "N"
  }

  return (
    <div className={styles.avatar}>
      {initials?.firstName && initials.firstName[0]}
      {initials?.lastName && initials?.lastName[0]}
    </div>
  );
}

export default Avatar;
