import React, { useContext, useEffect, useState } from "react";
import PageWrap from "../common/PageWrap";
import { AppContext } from "../../GlobalContext";
import NetworkService from "../../NetworkService";
import styles from "./InvoicesPage.module.scss";
import { Col, Container, Row } from "../common/Layout";
import TransactionsTable from "../tables/TransactionsTable";
import { DatePicker, DateRangePicker, SelectPicker } from "rsuite";
import moment from "moment";
import Button from "../common/Button";
import InvoicesTable from "../tables/InvoicesTable";
import { InvoiceCards } from "../Cards";
const { afterToday, before, combine } = DateRangePicker;

function InvoicesPage() {
  const { user: a } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [filterData, setFilterData] = useState({});

  const getInvoices = (data) => {
    setLoading(true);
    NetworkService.getInvoices(filterData)
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((e) => {
        setData([]);
        setLoading(false);
      });
  };

  const setDateFilterData = (val) => {
    let dateRemap;
    if (val != null) {
      dateRemap = moment(val).startOf("month").format("YYYY-MM-DD");
    }
    setFilterData({ ...filterData, date: dateRemap });
  };

  return (
    <PageWrap>
      <Container className={styles.ridesPageWrap}>
        <div className={styles.header}>Select dates and month period to review invoices:</div>

        <Row className={styles.tableWrap}>
          <Row className={styles.filters}>
            <DatePicker
              oneTap
              block
              size="lg"
              limitStartYear={5}
              limitEndYear={2}
              shouldDisableDate={combine(afterToday(), before(new Date("01-01-2020")))}
              className={styles.datePicker}
              preventOverflow
              format="yyyy-MM"
              onChange={(val) => {
                setDateFilterData(val);
              }}
            />
            <SelectPicker
              placeholder="Select period"
              size="lg"
              data={[
                { label: "First half", value: 1 },
                { label: "Second half", value: 2 },
              ]}
              onSelect={(val) => setFilterData({ ...filterData, period: val })}
              searchable={false}
            />

            <Button
              className={styles.fetchButton}
              disabled={!filterData.period || !filterData.date}
              variant="green"
              onClick={() => getInvoices(filterData)}>
              Show invoices
            </Button>
          </Row>
        </Row>

        <Row className={styles.tableWrap}>
          <div className={styles.tableHeader}>
            Invoices for selected dates: <b>{data.length}</b>
          </div>
        </Row>

        {data.length == 0 && (
          <div style={{ textAlign: "center", margin: "100px 0" }}>
            We haven't found your invoices for selected dates. Please select another month or period
          </div>
        )}

        {data.length > 0 && (
          <Row className={styles.cardWrap}>
            {data?.map((item, index) => (
              <InvoiceCards invoice={{ ...item, id: index }} />
            ))}
          </Row>
        )}
      </Container>
    </PageWrap>
  );
}

export default InvoicesPage;
