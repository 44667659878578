import React, { useState } from "react";
import ReactModalWrap from "../common/ReactModalWrap";
import styles from "./Modal.module.scss";
import Button from "../common/Button";
import NetworkService from "../../NetworkService";
import { downloadFile } from "../utils";

function IdentificationForm({ isOpen, close }) {

  const [loading, setLoading] = useState(false)

  const downloadIdentificationForm = () => {
    setLoading(true)
    NetworkService.getIdentificationForm().then((res) => {
      if (res?.data) {
        downloadFile(res?.data, `Identification-form.pdf`);
        setLoading(false)
      }
      close();
    });
  };

  return (
    <ReactModalWrap isOpen={isOpen} close={close}>
      <div className={styles.content}>
        <div className={styles.header}>Download identification form</div>
        <Button disabled={loading} onClick={() => downloadIdentificationForm()} variant="green" className={styles.downloadFormButton}>
          Download Form
        </Button>
      </div>
    </ReactModalWrap>
  );
}

export default IdentificationForm;
