import React, { useContext, useState } from "react";
import styles from "./Login.module.scss";
import { Col, Row } from "../common/Layout";
import TextField from "../common/TextField";
import Button from "../common/Button";
import { AppContext } from "../../GlobalContext";

import backgroundLeft from "../../static/images/bcg_left_login.svg";

import NetworkService from "../../NetworkService";
import { Link } from "react-router-dom";
import DownloadAppSection from "../common/DownloadAppSection";

const initialData = {
  email: "",
  password: "",
};

function Login() {
  const [formData, setFormData] = useState(initialData);

  const { user: a } = useContext(AppContext);

  const [user, setUser] = a;

  const [error, setError] = useState(false);

  const handleFormDataChange = (propName, value) => {
    setFormData({
      ...formData,
      [propName]: value,
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    loginFunc();
  };

  const loginFunc = (e) => {
    setError(false);
    NetworkService.login(formData)
      .then((res) => {
        setUser(res);
      })
      .catch((res) => {
        setError(true);
      });
  };

  return (
    <Row className={styles.loginPage}>
      <Col style={{ background: `url(${backgroundLeft})` }} xl={6} lg={6} xs={12} className={styles.loginLeft}>
        <div>
          <div className={styles.header}>Welcome</div>
          <div className={styles.description}>Login with your account, please enter your e-mail and password. </div>

          <form onSubmit={(e) => formSubmit(e)} className={styles.loginForm}>
            <TextField
              onChange={(val) => handleFormDataChange("email", val)}
              name="email"
              type="email"
              placeHolder="E-mail"
            />
            <TextField
              onChange={(val) => handleFormDataChange("password", val)}
              name="password"
              type="password"
              placeHolder="Password"
            />
            {error && <div className={styles.wrongCredentials}>Wrong email or password.</div>}
            <Button className={styles.submitButton} variant="green" type="submit">
              Login
            </Button>
            <Link className={styles.forgotPasswordLink} to="/forgot-password">
              Forgot password?
            </Link>
          </form>
        </div>
      </Col>
      <DownloadAppSection />
    </Row>
  );
}

export default Login;
