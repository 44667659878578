import React, { useState } from "react";
import styles from "./Search.module.scss";
import TextField from "./TextField";
import searchIcon from "../../static/images/seacrh-icon.svg";

function Search({ placeHolder, defaultValue, onChange }) {
  const [searchValue, setSearchValue] = useState(defaultValue || "");

  const onValChange = (val) => {
    setSearchValue(val);
    onChange(val);
  };

  return (
    <div className={styles.searchWrap}>
      <TextField placeHolder={placeHolder} className={styles.searchInput} onChange={onValChange} value={searchValue} />
      <img alt="search icon" className={styles.searchIcon} src={searchIcon} />
    </div>
  );
}

export default Search;
