import React from "react";
import styles from "./Footer.module.scss";
import { Col, Container } from "./common/Layout";
import cargoLogo from "../static/images/cargo-logo-header-dark.svg";
import bigLogo from "../static/images/footer_img_right.svg";

function Footer() {
  return (
    <Container className={styles.footerWrap}>
      <Col className={styles.leftSection} xl={6} lg={6} md={12} xs={12}>
        <img className={styles.logo} alt="logo" src={cargoLogo} />
        <div className={styles.supportWrap}>
          <div className={styles.supportHeader}>Contact support: </div>
          <div>
            <a className={styles.link} href="mailto:support@appcargo.com">
              support@appcargo.com
            </a>
          </div>
        </div>
        <div className={styles.watermark}>©️ CAR:GO Company 2023</div>
      </Col>
      <Col className={styles.rightSection} xl={6} lg={6} md={12} xs={12}>
        <img className={styles.businessLogo} src={bigLogo} alt="business logo"/>
      </Col>
    </Container>
  );
}

export default Footer;
