import React, { useContext, useState } from "react";
import styles from "./Header.module.scss";
import cargoLogo from "../static/images/cargo-logo-header.svg";
import { Container } from "./common/Layout";
import { Link } from "react-router-dom";
import burger from "../static/burger.svg";
import X from "../static/burger-close.svg";
import { AppContext } from "../GlobalContext";
import Button from "./common/Button";
import Popover from "./common/Popover";
import Avatar from "./common/Avatar";

function Header() {
  const { openBurger: a, companyData: b, user: c } = useContext(AppContext);
  const [openBurger, setOpenBurger] = a;
  const [companyData, setCompanyData] = b;
  const [user, setUser] = c;
  const [profilePopover, setProfilePopover] = useState(false);

  return (
    <div className={styles.headerWrap}>
      <Container className={styles.header}>
        <img
          alt="burger"
          id="burger-button-id"
          className={styles.burger}
          onClick={(e) => {
            setOpenBurger(!openBurger);
          }}
          src={openBurger ? X : burger}
        />
        <Link to="/">
          <img alt="logo" src={cargoLogo} />
        </Link>

        <div className={styles.companyName}>{companyData?.company?.name}</div>

        <Popover
          onClickOutside={() => setProfilePopover(false)}
          isOpen={profilePopover}
          content={
            <div className={styles.popoverButtons}>
              <div>{user?.name}</div>
              <div onClick={() => setUser(null)} className={styles.signOut} variant="white">
                Sign out
              </div>
            </div>
          }>
          <div
            onClick={() => {
              setProfilePopover(!profilePopover);
            }}>
            <Avatar user={user?.user} />
          </div>
        </Popover>
      </Container>
    </div>
  );
}

export default Header;
