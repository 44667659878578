import React, { useState } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "tippy.js/themes/light-border.css";
import classnames from "classnames";
import styles from "./Popover.module.scss";

export default function Popover({ children, isOpen, onClickOutside, content, placement = "bottom" }) {
  const [instance, setInstance] = useState();
  const controlled = isOpen !== undefined;
  const additionalProps = controlled
    ? {
        visible: isOpen,
        onClickOutside: () => {
          if (onClickOutside) {
            onClickOutside();
          }
        },
      }
    : {};
  const popoverContent = React.isValidElement(content)
    ? React.cloneElement(content, { onClose: () => instance.hide() })
    : content;
  return (
    <Tippy
      animation="scale"
      inertia
      interactive
      trigger="click"
      appendTo="parent"
      placement={placement}
      theme="light-border"
      onCreate={setInstance}
      {...additionalProps}
      content={popoverContent}>
      {children}
    </Tippy>
  );
}

export function PopoverBody({ children, className, ...rest }) {
  return (
    <div className={classnames(styles.popoverBody, className)} {...rest}>
      {children}
    </div>
  );
}
