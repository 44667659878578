import React from "react";
import ReactModalWrap from "../common/ReactModalWrap";
import styles from "./Modal.module.scss";
import Button from "../common/Button";

function ConfirmationModal({ isOpen, close, handleYes }) {
  return (
    <ReactModalWrap isOpen={isOpen} close={close}>
      <div className={styles.content}>
        <div className={styles.header}>Are you sure that you want to delete this User?</div>
        <div className={styles.subHeader}>
          If you delete this user he/she will lost all benefits from company account. Also you don't have options to
          recovery this account.
        </div>
        <div className={styles.buttonWrap}>
          <Button variant="white" className={styles.button} onClick={() => handleYes()}>
            YES
          </Button>
          <Button variant="white" className={styles.button} onClick={() => close()}>
            NO
          </Button>
        </div>
      </div>
    </ReactModalWrap>
  );
}

export default ConfirmationModal;
