import React, { useState } from "react";
import styles from "./Login.module.scss";
import { Col, Row } from "../common/Layout";
import TextField from "../common/TextField";
import Button from "../common/Button";
import backgroundLeft from "../../static/images/bcg_left_login.svg";
import NetworkService from "../../NetworkService";
import { toasterFunction } from "../utils";
import { Message } from "rsuite";
import { useNavigate, useSearchParams } from "react-router-dom";
import DownloadAppSection from "../common/DownloadAppSection";

const initialData = {
  password: "",
  verifyPassword: "",
};

function ResetPasswordPage() {
  const [formData, setFormData] = useState(initialData);

  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const handleFormDataChange = (propName, value) => {
    setFormData({
      ...formData,
      [propName]: value,
    });
  };

  const [searchParams] = useSearchParams();

  const formSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    loginFunc();
  };

  const loginFunc = (e) => {
    setError(false);
    NetworkService.resetPassword(formData.password, searchParams.get("token"))
      .then((res) => {
        toasterFunction(
          <Message closable type="success">
            Password has been changed successfully.
          </Message>
        );
        navigate("/login");
      })
      .catch((res) => {});
  };

  return (
    <Row className={styles.loginPage}>
      <Col style={{ background: `url(${backgroundLeft})` }} xl={6} lg={6} xs={12} className={styles.loginLeft}>
        <div>
          <div className={styles.header}>Welcome</div>
          <div className={styles.description}>Please enter your new password </div>

          <form onSubmit={(e) => formSubmit(e)} className={styles.loginForm}>
            <TextField
              onChange={(val) => handleFormDataChange("password", val)}
              name="password"
              type="password"
              placeHolder="Password"
            />
            <TextField
              onChange={(val) => handleFormDataChange("verifyPassword", val)}
              name="verifyPassword"
              type="password"
              placeHolder="Confirm Password"
            />
            {error && <div className={styles.wrongCredentials}>Passwords doesn't match</div>}
            <Button
              disabled={formData.password !== formData.verifyPassword || !formData.password || !formData.verifyPassword}
              className={styles.submitButton}
              variant="green"
              type="submit">
              Change password
            </Button>
          </form>
        </div>
      </Col>
      <DownloadAppSection />
    </Row>
  );
}

export default ResetPasswordPage;
