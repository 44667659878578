import React, { useState } from "react";
import styles from "./TextField.module.scss";
import classNames from "classnames";
import showPassword from "../../static/ic_eye_open.svg"
import hidePassword from "../../static/ic_eye_close.svg"

function TextField({ type = "text", className, label, labelClassName, placeHolder, onChange, name, ...rest }) {

  const [typeState, setTypeState] = useState(type)

  const handlePasswordShow  = (show) => {
      setTypeState(show ? "text" : "password")
  }

  return (
    <div className={styles.inputWrap}>
      {label && (
        <label htmlFor={name} className={classNames(labelClassName, styles.labelClass)}>
          {label}
        </label>
      )}
      <input
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeHolder}
        className={classNames(styles.inputClass, className)}
        type={typeState}
        {...rest}
      />
      {type === "password" && <img alt="show hide password" onClick={() => handlePasswordShow(typeState !== "text")} className={styles.passwordShowIcon} src={typeState == "password" ? showPassword : hidePassword} /> }
    </div>
  );
}

export default TextField;
