import React, { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import styles from "./DatePickerWrap.module.scss";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

function DatePickerWrap() {
  const { afterToday } = DateRangePicker;

  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState([]);

  useEffect(() => {
    const from = Number.parseInt(searchParams.get("from"));
    const to = Number.parseInt(searchParams.get("to"));

    if (from && to) {
      setValue([new Date(from), new Date(to)]);
    } else {

      const from = moment().startOf("month").valueOf()
      const to = moment().valueOf()

      setValue([new Date(from), new Date(to)]);
    }
  }, [searchParams]);

  const onPickerChange = (val) => {
    if (val == null) {
      setValue([]);
      setSearchParams({});
      return;
    } else {
      const from = moment(val[0]).startOf("day").valueOf();
      const to = moment(val[1]).endOf("day").valueOf();
      searchParams.set("from", from)
      searchParams.set("to", to)
      setSearchParams(searchParams.toString());
    }
  };

  return (
    <div className={styles.pickerWrap}>
      <DateRangePicker
        preventOverflow
        placeholder="Set a date range"
        size="lg"
        value={value}
        onChange={onPickerChange}
        shouldDisableDate={afterToday()}
        className={styles.datePicker}
        format="yyyy-MM-dd"
        defaultCalendarValue={[new Date(), new Date()]}
      />
    </div>
  );
}

export default DatePickerWrap;
