import React from "react";
import checked from "../../static/images/ic_check.svg";
import styles from "./Checkbox.module.scss";
import CheckIcon from "@rsuite/icons/Check";
import classNames from "classnames";

function Checkbox({ selected, onChange, className }) {
  return (
    <div
      className={classNames(styles.checkbox, className, {
        [styles.selected]: selected,
      })}
      onClick={() => onChange()}>
      {selected && <CheckIcon fill="#171B1F" />}
    </div>
  );
}

export default Checkbox;
