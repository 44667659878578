import React from "react";
import TableWrapper from "../common/TableWrapper";
import Button from "../common/Button";
import NetworkService from "../../NetworkService";
import { downloadFile } from "../utils";

const columns = [
  {
    header: "#",
    dataKey: "id",
    options: {},
  },
  {
    header: "Fleet Id",
    dataKey: "the_transaction_amount",
    options: {
      width: 150,
    },
  },
  {
    header: "Fleet Name",
    render: (e) => <>{e.name}</>,
    options: {
      width: 250,
    },
  },

  {
    header: "Download invoice",
    render: (e) => (
      <Button
        variant="white"
        onClick={() => {
          NetworkService.downloadInvoice(e).then((res) => {
            if (res?.data) {
              downloadFile(res?.data, `${e.start_date}-${e.end_date}-${e.the_transaction_amount}.pdf`);
            }
          });
        }}>
        Download
      </Button>
    ),
    options: {
      width: 250,
    },
  },
];

function InvoicesTable({ data, loading, renderEmpty }) {
  return (
    <TableWrapper
      loading={loading}
      columns={columns}
      renderEmpty={renderEmpty}
      data={data?.map((item, index) => ({ ...item, id: index })) || []}
    />
  );
}

export default InvoicesTable;
