import axios from "axios";
import moment from "moment";

export class NetworkService {
  constructor() {
    this.url = process.env.REACT_APP_API_URL || "http://139.59.128.196:8000/api";
    console.log(process.env);
    this.jwtToken = null;
    this.handle401 = null;
    this.handle403 = null;
    this._handleErrors = this._handleErrors.bind(this);
    this._handleResponse = this._handleResponse.bind(this);
  }

  _makeRequest(method, path, config = {}, auth = false) {
    let { headers, body, params, ...rest } = config;
    let additionalHeaders = {};
    if ((method === "POST" || method === "PUT") && typeof body === "object" && !(body instanceof FormData)) {
      additionalHeaders["content-type"] = "application/json";
      body = JSON.stringify(body);
    }
    if (this.jwtToken) {
      additionalHeaders["Authorization"] = "Bearer " + this.jwtToken;
    }

    return axios({
      method: method,
      url: `${this.url}/${path}`,
      ...config,
      headers: {
        Authorization: "Bearer " + this.jwtToken,
        ...headers,
      },
    })
      .then(this._handleResponse)
      .catch(this._handleErrors);
  }

  _handleErrors(error) {
    if(error.response.status == 401) {
      localStorage.setItem("user", null);
      window.location.reload()
    }
    console.log(error);
    throw error.response;
  }

  async _handleResponse(response) {
    let body = null;
    let contentType = response.headers["content-type"];
    if (contentType && contentType.startsWith("application/json")) {
      body = await response.data;
    } else {
      return response;
    }
    return body;
  }

  getRides(from, to, pagination, search) {
    return this._makeRequest("GET", `company/drives`, {
      params: {
        from: from,
        to: to,
        perPage: pagination?.size,
        page: pagination?.page,
        search: search,
      },
    });
  }
  getRidesForUser(userId, from, to, pagination, search) {
    return this._makeRequest("GET", `company/drives/${userId}`, {
      params: {
        from: from,
        to: to,
        perPage: pagination?.size,
        page: pagination?.page,
        search: search,
      },
    });
  }

  getUsers(pagination, search) {
    return this._makeRequest("GET", `company/users`, {
      params: {
        perPage: pagination?.size,
        page: pagination?.page,
        search: search,
      },
    });
  }

  getUserById(id) {
    return this._makeRequest("GET", `company/users/${id}`, {});
  }
  addUser(userModalData) {
    return this._makeRequest("POST", `company/users`, {
      data: userModalData,
    });
  }

  deleteUser(id) {
    return this._makeRequest("PUT", `company/users/delete`, {
      data: {
        user_id: id,
      },
    });
  }

  editUser(data) {
    return this._makeRequest("PUT", `company/users`, {
      data: {
        user_id: data.id,
        ...data,
      },
    });
  }

  editUserEmployeeDetails(userModalData) {
    return this._makeRequest("POST", `company/users/update-employee-details`, {
      data: {
        user_id: userModalData?.id,
        employee_details: userModalData?.employee_details,
      },
    });
  }
  editUserSpendingLimit(userModalData) {
    return this._makeRequest("POST", `company/users/update-spending-limit`, {
      data: {
        user_id: userModalData?.id,
        limit: userModalData?.limit,
      },
    });
  }

  login(formData) {
    return this._makeRequest("POST", `auth/login`, {
      data: {
        email: formData?.email,
        password: formData?.password,
      },
    });
  }
  forgotPassword(email) {
    return this._makeRequest("POST", `auth/reset-password`, {
      data: {
        email: email,
      },
    });
  }
  resetPassword(password, token) {
    return this._makeRequest("POST", `auth/handle-reset-password`, {
      data: {
        password: password,
      },

      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }

  editCompanyCreditLowLimit(credit) {
    return this._makeRequest("POST", `company/set-credit-low-limit`, {
      data: {
        credit: credit,
      },
    });
  }
  requestCompanyCredit(credit) {
    return this._makeRequest("POST", `company/request-credit`, {
      data: {
        credit: credit,
      },
    });
  }

  getTransactions(pagination, search) {
    return this._makeRequest("GET", `company/transactions`, {
      params: {
        perPage: pagination?.size,
        page: pagination?.page,
        search: search,
      },
    });
  }

  getTodaysData() {
    return this._makeRequest("GET", `company`, {});
  }

  getMonthlyReport(formData) {
    const month = moment(formData.date).get("M");
    const year = moment(formData.date).get("y");
    const email = formData.email;

    return this._makeRequest("POST", `company/monthly-report`, {
      data: {
        month: month + 1,
        year: year,
        email: email,
      },
    });
  }
  getAllInvoices(formData) {
    const month = moment(formData.date).get("M");
    const year = moment(formData.date).get("y");
    const email = formData.email;

    return this._makeRequest("POST", `company/download-fiscal-invoices`, {
      params: {
        month: month + 1,
        year: year,
      },
      data: {
        email: email,
      },
    });
  }

  getAllInvoicesForUser(userId, formData) {
    const month = moment(formData.date).get("M");
    const year = moment(formData.date).get("y");
    const email = formData.email;

    return this._makeRequest("POST", `company/download-fiscal-invoices/${userId}`, {
      params: {
        month: month + 1,
        year: year,
      },
      data: {
        email: email,
      },
    });
  }
  getContract(formData) {
    return this._makeRequest("POST", `company/contract`, {
      data: {
        email: formData?.responsiblePersonEmail,
        responsible_person: formData?.responsiblePerson,
        position: formData?.responsiblePersonPosition,
      },
    });
  }
  getAnnex(formData) {
    return this._makeRequest("POST", `company/annex`, {
      responseType: "blob",
      data: {
        responsible_person: formData?.responsiblePerson,
        position: formData?.responsiblePersonPosition,
      },
    });
  }

  getPersonalDataAgreement(formData) {
    console.log(formData);
    return this._makeRequest("POST", `company/personal-data-agreement`, {
      responseType: "blob",
      data: {
        responsible_person: formData?.responsiblePerson,
        responsible_person_email: formData?.responsiblePersonEmail,
      },
    });
  }

  getInvoices(formData) {
    return this._makeRequest("GET", `company/invoices`, {
      params: formData,
    });
  }
  downloadInvoice(data) {
    return this._makeRequest("GET", `company/download/invoice`, {
      responseType: "blob",
      params: {
        fleet_id: data?.the_transaction_amount,
        start_date: data?.start_date,
        end_date: data?.end_date,
        period: data?.period,
      },
    });
  }

  getIdentificationForm(formData) {
    return this._makeRequest("GET", `company/download/identification-form`, {
      responseType: "blob",
    });
  }
}

export default new NetworkService();
