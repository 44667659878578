import React, { useEffect, useState } from "react";
import ReactModalWrap from "../common/ReactModalWrap";
import styles from "./Modal.module.scss";
import Button from "../common/Button";
import { Days, toasterFunction, validWorkingHoursForADay } from "../utils";
import { SelectPicker } from "rsuite";
import TextField from "../common/TextField2";
import Checkbox from "../common/Checkbox";
import deleteIcon from "../../static/images/delete.svg";
import plusIcon from "../../static/images/ic_close.svg";

function AllowedTimeTables({ open, close, initalModalData, onSubmit }) {
  const [allowedTimetables, setAllowedTimetables] = useState([]);

  const [selectedDays, setSelectedDays] = useState([]);

  const onSubmitWrap = (selectedDays, allowedTimetables) => {
    if (!validWorkingHoursForADay(allowedTimetables[selectedDay], toasterFunction)) {
      return;
    }

    if (selectedDays.length == 0) {
      onSubmit({
        time_slots: JSON.stringify(allowedTimetables),
      });
    } else {
      let timeSlotsDraft = allowedTimetables;
      const todaysTimetable = allowedTimetables[selectedDay];
      selectedDays.forEach((day) => (timeSlotsDraft[day] = todaysTimetable));
      onSubmit({
        time_slots: JSON.stringify(timeSlotsDraft),
      });
    }
  };

  useEffect(() => {
    if (initalModalData) {
      const company_user_info = initalModalData?.company_user_info;
      if (company_user_info) {
        const allowedTimetablesData = company_user_info.allowed_time_slots || "";
        setAllowedTimetables(JSON.parse(allowedTimetablesData));
      }
    }
  }, [initalModalData, open]);

  const onChange = (data) => {
    console.log(data);
    setAllowedTimetables((prev) => {
      return { ...prev, [selectedDay]: data };
    });
  };
  const [selectedDay, setSelectedDay] = useState(initalModalData.day || "monday");

  const onUpdateSlot = (day, slot, newValue, parameter) => {
    setAllowedTimetables((old) => {
      const days = old[day] || [];
      const newDay = days.map((val) => {
        if (val === slot) {
          let draft = val;
          draft[parameter] = newValue;
          return draft;
        } else {
          return val;
        }
      });
      return {
        ...old,
        [day]: newDay,
      };
    });
  };

  const onRemoveSlot = (day, slot) => {
    setAllowedTimetables((old) => {
      const days = old[day] || [];
      return {
        ...old,
        [day]: days.filter((val) => val !== slot),
      };
    });
  };

  const onAddNewSlot = (day) => {
    setAllowedTimetables((old) => {
      const days = old[day] || [];
      return {
        ...old,
        [day]: [...days, ["00:00", "00:00"]],
      };
    });
  };

  return (
    <ReactModalWrap isOpen={open} close={close}>
      <div className={styles.content}>
        <div className={styles.header}>Set day and time </div>
        <SelectPicker
          size="lg"
          cleanable={false}
          value={selectedDay}
          onChange={(val) => setSelectedDay(val)}
          searchable={false}
          style={{ margin: "20px 0 20px" }}
          menuStyle={{ zIndex: 999999 }}
          data={Object.keys(Days).map((d) => ({ label: Days[d].label, value: d }))}
        />

        <DailyTimeTable
          onRemoveSlot={(slot) => onRemoveSlot(selectedDay, slot)}
          onUpdateSlot={(slot, newValue, parameter) => onUpdateSlot(selectedDay, slot, newValue, parameter)}
          onAddNewSlot={() => onAddNewSlot(selectedDay)}
          allowedTimetables={allowedTimetables[selectedDay]}
          onChange={onChange}
        />

        <CopySchedule selectedDays={selectedDays} setSelectedDays={setSelectedDays} />

        <Button
          onClick={() => {
            onSubmitWrap(selectedDays, allowedTimetables);
          }}
          className={styles.userSubmit}
          variant="green">
          Save changes
        </Button>
      </div>
    </ReactModalWrap>
  );
}

export default AllowedTimeTables;

const DailyTimeTable = ({ allowedTimetables = [], onChange, onAddNewSlot, onRemoveSlot, onUpdateSlot }) => {
  console.log(allowedTimetables);

  return (
    <>
      {allowedTimetables?.map((time) => (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <TimeTableItem onChange={(slot, value, parameter) => onUpdateSlot(slot, value, parameter)} time={time} />
          <Button
            className={styles.addNewSlot}
            variant="white"
            onClick={() => {
              onRemoveSlot(time);
            }}>
            <img src={deleteIcon} />
            Delete
          </Button>
        </div>
      ))}
      <Button onClick={onAddNewSlot} className={styles.addNewSlot} variant="white">
        <img src={plusIcon} />
        Add time
      </Button>
    </>
  );
};

const CopySchedule = ({ selectedDays, setSelectedDays }) => {
  const [expanded, setExpanded] = useState(false);

  const onChange = (day, val) => {
    if (val) {
      setSelectedDays([...selectedDays, day]);
    } else {
      const filtered = selectedDays.filter((c) => c != day);
      console.log(selectedDays);
      setSelectedDays(filtered);
    }
  };

  return (
    <>
      <div onClick={() => setExpanded(!expanded)} className={styles.dayItemsHeading}>
        Copy this schedule
        <img style={{
          transform: `rotate(${expanded ? "45deg" : "0deg"})`
        }} src={plusIcon} />
      </div>
      {expanded && (
        <>
          <div className={styles.cloneDescription}>
            You can copy your schedule on other days if you want, just check and save.
          </div>
          <div className={styles.dayItemsWrap}>
            {Object.keys(Days).map((d) => (
              <DayItem
                day={Days[d]}
                selected={selectedDays.includes(d)}
                key={d}
                onChange={(value) => onChange(d, value)}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

const DayItem = ({ selected, day, onChange }) => {
  return (
    <div onClick={() => onChange(!selected)} className={styles.dayItem}>
      <div className={styles.cselect}>
        <Checkbox selected={selected} onChange={() => onChange(!selected)} />
      </div>
      <div className={styles.clabel}>{day.label}</div>
    </div>
  );
};

const TimeTableItem = ({ time, onChange }) => {
  console.log(time);

  return (
    <div className={styles.timeTableItemWrap}>
      <TimeInput value={time[0]} onChange={(val) => onChange(time, val, 0)} />
      <TimeInput value={time[1]} onChange={(val) => onChange(time, val, 1)} />
    </div>
  );
};

function TimeInput({ value = "00:00", onChange }) {
  console.log(value);
  let [hours, minutes] = value.split(":");

  const keyPressHandler = (e) => {
    console.log(e.which);
    if (!(e.which >= 48 && e.which <= 57)) {
      e.preventDefault();
    }
  };

  const parseNumber = (val) => {
    if (!val.length) {
      return 0;
    } else {
      return Number.parseInt(val);
    }
  };

  const onHoursChange = (val) => {
    if (val.length > 2) return;
    let n = parseNumber(val);
    if (n >= 0 && n <= 23) {
      onChange(val + ":" + minutes);
    }
  };

  const onMinutesChange = (val) => {
    if (val.length > 2) return;
    let n = parseNumber(val);
    if (n >= 0 && n <= 59) {
      onChange(hours + ":" + val);
    }
  };

  return (
    <span className={styles.timeInput}>
      <TextField
        value={hours}
        onChange={onHoursChange}
        onBlur={() => {
          if (hours.length < 2) {
            const update = hours.length === 0 ? "00" : "0" + hours;
            onHoursChange(update);
          }
        }}
        onKeyPress={keyPressHandler}
      />
      <span>:</span>
      <TextField
        value={minutes}
        onChange={onMinutesChange}
        onBlur={() => {
          if (minutes?.length < 2) {
            const update = minutes.length === 0 ? "00" : "0" + minutes;
            onMinutesChange(update);
          }
        }}
        onKeyPress={keyPressHandler}
      />
    </span>
  );
}
