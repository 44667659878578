import React, { useEffect, useState } from "react";
import ReactModalWrap from "../common/ReactModalWrap";
import styles from "./Modal.module.scss";
import TextField from "../common/TextField";
import Button from "../common/Button";
import NetworkService from "../../NetworkService";
import { toasterFunction } from "../utils";
import { Message } from "rsuite";

function UserModal({ open, close, initalModalData, onSubmit }) {
  const [userModalData, setUserModalData] = useState(initalModalData);

  useEffect(() => {
    let data = {};

    if (initalModalData) {
      data = {
        id: initalModalData?.id || undefined,
        email: initalModalData?.email || undefined,
        phone: initalModalData?.phone || undefined,
        limit: initalModalData?.company_user_info?.spending_credit_limit,
        employee_details: initalModalData?.company_user_info?.employee_details,
        name: initalModalData?.firstName + " " + initalModalData?.lastName,
      };
    }

    setUserModalData(data);
  }, [initalModalData, open]);

  const submit = () => {
    if (userModalData?.id) {
      NetworkService.editUser(userModalData)
        .then(() => {
          toasterFunction(
            <Message closable type="success">
              User successfully edited.
            </Message>
          );
          onSubmit();
        })
        .catch((e) => {
          console.log(e);
          toasterFunction(
            <Message closable type="error">
              An error has ocurred
            </Message>
          );
        });
    } else {
      NetworkService.addUser(userModalData)
        .then((res) => {
          console.log(res);
          toasterFunction(
            <Message closable type="success">
              User successfully added.
            </Message>
          );
          onSubmit();
        })
        .catch((err) => {
          console.log(err?.data);
          toasterFunction(
            <Message closable type="error">
              {err?.data?.original}
              {err?.data?.phone}
            </Message>
          );
          onSubmit();
        });
    }
  };

  return (
    <ReactModalWrap isOpen={open} close={close}>
      <div className={styles.content}>
        <div className={styles.header}>{userModalData?.id ? userModalData?.name : "New User"}</div>

        <TextField
          disabled={userModalData?.id}
          label="Phone"
          placeHolder="Phone number"
          value={userModalData?.phone}
          onChange={(val) => setUserModalData({ ...userModalData, phone: val })}
        />

        <TextField
          label="Employee details"
          placeHolder="Employee details"
          value={userModalData?.employee_details}
          onChange={(val) => setUserModalData({ ...userModalData, employee_details: val })}
        />

        {userModalData?.id && (
          <>
            <TextField
              disabled={true}
              label="Email"
              placeHolder="Email"
              value={userModalData?.email}
              onChange={(val) => setUserModalData({ ...userModalData, email: val })}
            />

            <TextField
              label="Spending limit"
              placeHolder="Spending limit"
              value={userModalData?.limit}
              onChange={(val) => setUserModalData({ ...userModalData, limit: val })}
            />
          </>
        )}

        <Button
          disabled={!userModalData?.id && !userModalData?.phone}
          onClick={() => {
            submit();
          }}
          className={styles.userSubmit}
          variant="green">
          {!initalModalData?.id  ? "Add user" : "Save changes"}
        </Button>
      </div>
    </ReactModalWrap>
  );
}

export default UserModal;
